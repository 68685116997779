import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import { Link as RouterLink } from "gatsby";
import { ContactInfo } from "./contactModal";

const useStyles = makeStyles((theme) => ({
    footer: {
        textAlign: "center",
        padding: "1rem 1rem 0",
        background: "#444",
        color: "#eee",
    },
    paragraph: {
        margin: "1rem auto",
        maxWidth: "70vw",
    },
    links: {
        textDecoration: "none",
        color: "inherit",
        ":hover": {
            textDecoration: "underline",
        },
    },
    text: {
        fontSize: "0.8rem",
    },
    ownership: {
        fontSize: "0.8rem",
        margin: "1rem auto 0",
    },
    logo: {
        maxWidth: "70%",
        width: "200px",
    },
    link: {
        display: "block",
        margin: "1rem",
        fontSize: "1.2rem",
        color: "#fff",
    },
    icon: {
        verticalAlign: "middle",
    },
    separator: {
        width: "75vw",
    },
}));

export default function Footer() {
    const classes = useStyles();
    return (
        <Grid container className={classes.footer}>
            <Grid item className={classes.paragraph}>
                <Typography style={{ fontSize: "1.1rem" }}>
                    Brampton &#8226; Mississauga &#8226; Toronto &#8226; York
                    &#8226; Oakville &#8226; Burlington &#8226; Milton &#8226;
                    Etobicoke
                </Typography>
                <ContactInfo
                    linkStyle={{
                        display: "block",
                        margin: "1rem",
                        fontSize: "1.2rem",
                        color: "#fff",
                    }}
                    iconStyle={{ verticalAlign: "middle" }}
                />
            </Grid>
            <hr className={classes.separator} />
            <Grid item sm={12} className={classes.paragraph}>
                <img
                    className={classes.logo}
                    src="/c21-logo.png"
                    alt="c21"
                ></img>
            </Grid>
            <Grid item sm={12} className={classes.paragraph}>
                <Typography className={classes.text}>
                    &copy; 2021 All rights reserved. Toronto Real Estate Board
                    (TREB) assumes no responsibility for the accuracy of any
                    information shown. The information provided herein must only
                    be used by consumers that have a bona fide interest in the
                    purchase, sale or lease of real estate and may not be used
                    for any commercial purpose or any other purpose.
                </Typography>
            </Grid>
            <Grid item sm={12} className={classes.paragraph}>
                <Typography className={classes.text}>
                    Amrish Prashar | Sales Person | CENTURY 21 LEGACY LTD.,
                    BROKERAGE Independently Owned & Operated
                </Typography>
                <Typography className={classes.text}>
                    7461 Pacific Cir, Mississauga, ON L5T 2A4 | 905-672-2200
                </Typography>
                <Typography className={classes.text}>
                    <RouterLink className={classes.links} to="/about/terms">
                        TERMS OF USE
                    </RouterLink>{" "}
                    |{" "}
                    <RouterLink className={classes.links} to="/about/policy">
                        PRIVACY POLICY
                    </RouterLink>
                </Typography>
                <Typography className={classes.ownership}>
                    Website design and implementation by
                    <a
                        style={{ textDecoration: "none", color: "white" }}
                        href="mailto:kashugoyal@gmail.com"
                    >
                        &nbsp; Kashish Goyal
                    </a>
                </Typography>
            </Grid>
        </Grid>
    );
}
