import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import Fab from "@material-ui/core/Fab";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CallIcon from "@material-ui/icons/Call";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import EmailIcon from "@material-ui/icons/Email";
import FacebookIcon from "@material-ui/icons/Facebook";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "300px",
        [theme.breakpoints.up("md")]: {
            width: "500px",
        },
        margin: "auto",
        textAlign: "center",
        padding: "1rem",
    },
    modal: {
        margin: `10rem auto`,
    },
    button: {
        margin: "auto",
    },
    cardTitle: {
        textAlign: "center",
    },
    link: {
        display: "block",
        margin: "1rem",
        fontSize: "1.2rem",
    },
    icon: {
        verticalAlign: "middle",
    },
    seperator: {
        width: "75%",
        margin: "1rem auto",
    },
    margin: {
        margin: theme.spacing(1),
        position: "fixed",
        bottom: "40px",
        right: "40px",
        [theme.breakpoints.down("sm")]: {
            bottom: "20px",
            right: "20px",
        },
        color: "#fff",
        fontWeight: "bold",
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export function ContactInfo(props) {
    return (
        <React.Fragment>
            <Link style={props.linkStyle} href="tel:647-502-5054">
                <CallIcon style={props.iconStyle} /> 647-502-5054
            </Link>
            <Link
                style={props.linkStyle}
                href="https://wa.me/16475025054?text=Hi%2C+I+would+love+to+schedule+a+session+with+you.+Please+tell+me+more."
            >
                <WhatsAppIcon style={props.iconStyle} /> WhatsApp
            </Link>
            <Link
                style={props.linkStyle}
                href="https://www.facebook.com/amrishprasherrealestate/"
            >
                <FacebookIcon style={props.iconStyle} /> Facebook
            </Link>
            <Link style={props.linkStyle} href="mailto:prasher73@yahoo.ca">
                <EmailIcon style={props.iconStyle} /> Email
            </Link>
        </React.Fragment>
    );
}

function ContactCard() {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardContent>
                <Typography variant="h6" className={classes.cardTitle}>
                    Schedule a free session with me to to get all your questions
                    answered.
                </Typography>
                <hr className={classes.seperator} />
                <ContactInfo
                    linkStyle={{
                        display: "block",
                        margin: "1rem",
                        fontSize: "1.2rem",
                    }}
                    iconStyle={{ verticalAlign: "middle" }}
                />
                <hr className={classes.seperator} />
                <Typography variant="h5" className={classes.cardTitle}>
                    Call, Email or Send me a text on Whatsapp today !!!
                </Typography>
            </CardContent>
        </Card>
    );
}

export function ContactModal() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid>
            <Button
                size="large"
                variant="outlined"
                color="primary"
                onClick={handleOpen}
                startIcon={<CallIcon />}
                className={classes.button}
            >
                Call Me Today !!!
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="Contact Card"
                aria-describedby="Contact Information"
            >
                <ContactCard />
            </Modal>
        </Grid>
    );
}

export function CallToContact() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Grid container>
            <Fab
                variant="extended"
                color="primary"
                aria-label="Call"
                className={classes.margin}
                onClick={handleOpen}
            >
                <CallIcon className={classes.extendedIcon} />
                Call Me Today !!!
            </Fab>
            <Modal
                open={open}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="Contact Card"
                aria-describedby="Contact Information"
            >
                <ContactCard />
            </Modal>
        </Grid>
    );
}
