import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { AppBar, Toolbar } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridGap: theme.spacing(3),
    },
    logo: {
        maxWidth: "260px",
    },
    navIcon:{
        fontWeight: "bold",
        fontSize: "1rem",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.8rem",
        },

    }
}));

export default function NavBar() {
    const classes = useStyles();
    return (
        <AppBar color="inherit" aria-label="menu" elevation={0} position="sticky">
            <Toolbar>
                <Container maxWidth="md">
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid container item lg={4} sm={12} justifyContent="center">
                            <Button component={Link} to="/">
                                <img src="/logo_horizontal_nobg.png" alt="logo" className={classes.logo} />
                            </Button>
                        </Grid>
                        <Grid container item justifyContent="space-evenly" lg={8} sm={12}>
                            <Grid item>
                                <Button className={classes.navIcon} component={Link} to="/">
                                    Home
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button className={classes.navIcon} component={Link} to="/buy">
                                    Buy
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button className={classes.navIcon} component={Link} to="/sell">
                                    Sell
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button className={classes.navIcon} component={Link} to="/invest">
                                    Invest
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button className={classes.navIcon} component={Link} to="/about">
                                    About
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Toolbar>
        </AppBar>
    )
}