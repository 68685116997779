import React from "react"
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core';
import NavBar from '../components/NavBar';
import Footer from '../components/footer';
import { CallToContact } from '../components/contactModal';

let theme = createTheme({
    palette: {
        primary: {
            main: "#78517c"
        },
        secondary:{
            main: "#83d1c4"
        }
    },
    typography: {
        fontFamily: [
            'Montserrat',
            'Urbanist',
            'sans-serif',
        ].join(','),
        h3: {
            fontFamily: [
                'Urbanist',
                'Montserrat',
                'sans-serif',
            ].join(','),
        },
        h4: {
            fontFamily: [
                'Urbanist',
                'Montserrat',
                'sans-serif',
            ].join(','),
        },
        h5: {
            fontFamily: [
                'Urbanist',
                'Montserrat',
                'sans-serif',
            ].join(','),
        },
    }

})
theme = responsiveFontSizes(theme);

// markup
export default function Layout({ children }) {

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <h1 style={{visibility: "hidden", display:"none"}}>
                Amrish Prasher Real Estate
            </h1>
            <NavBar></NavBar>
            {children}
            <CallToContact></CallToContact>
            <Footer></Footer>
        </ThemeProvider>
    )
}
